function animateAbout() {
  inView('.about-us').once('enter', function() {
    const descr = $('.about-us .list')
    const btn = $('.about-us .btn')
    const img = $('.about-us__img')
    const tl = new TimelineMax()
    tl.to(descr, 0.5, {
      y: '0',
      autoAlpha: 1,
      ease: Power0.easeNone
    })
      .to(btn, 0.5, {
        y: '0',
        autoAlpha: 1,
        ease: Power0.easeNone
      })
      .to(
        img,
        1,
        {
          right: '-10%',
          autoAlpha: 1,
          ease: Power0.easeNone
        },
        '-=1'
      )
  })
}
function animateTypes() {
  const item = $('.js-carousel .carousel__item')
  const tl = new TimelineMax()
  inView.offset(50)
  inView('.js-type').once('enter', function() {
    tl.staggerFromTo(
      item,
      0.6,
      {
        y: 30,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1
      },
      0.1
    )
  })
}
const jsCarousel = $('.js-carousel')
if (jsCarousel.length) {
  jsCarousel.each(function() {
    var slick = $(this),
      itemQuantity = $(this).data('item'),
      itemScroll = $(this).data('item-scroll'),
      itemDots = $(this).data('dots'),
      scrollSpeed = $(this).data('scroll-speed')

    slick.slick({
      dots: itemDots || false,
      arrows: true,
      infinite: true,
      speed: 600,
      slidesToShow: itemQuantity || 4,
      slidesToScroll: itemScroll || 4,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: scrollSpeed || 5000,
      responsive: [
        {
          breakpoint: 1370,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 1270,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: false
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          }
        },
        {
          breakpoint: 791,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false
          }
        }
      ]
    })
  })
}

if ($('.gallery__items').length) {
  // init Isotope
  const container = $('.gallery__items').isotope({
    itemSelector: '.product-card',
    layoutMode: 'fitRows'
  })

  $('.gallery__menu-link').click(function(e) {
    e.preventDefault()
    container.isotope('updateSortData').isotope({
      sortBy: 'random'
    })
  })
}

(function () {
	if ($('.js-review-slider').length) {

		$('.js-review-slider').owlCarousel({
			items: 1,
			loop: true,
			autoplay: false,
			animateIn: 'flipInX',
			margin: 30,
			stagePadding: 30,
			smartSpeed: 450,
			responsiveClass: true,
			responsive: {
				600: {
					items: 1
				},
				1000: {
					items: 1,
					nav: false
				}
			}
		});
	};
})();
function initBrandSlider() {
  var slider = $('.js-brand_slider');

  // Animate  Slide
  function animateBrandSlideStart() {
      var tl = new TimelineMax();
      tl
          .staggerFromTo('.js-brand_slider .slick-active img', 0.5, {
              opacity: 0,
              y: 100,
              ease: Power0.easeInOut
          }, {
              opacity: 1,
              y: 0,
          }, 0.1)
          .fromTo('.js-brand_slider .slick-active img', 0.5, {
              y: 0,
              ease: Power0.easeInOut
          }, {
              y: 100,
          }, 4);;
  }

  if ($(slider).length > 0) {
      $(slider)
          .on(
              'init',
              function (event, slick, currentSlide, nextSlide) {
                  animateBrandSlideStart()

              }
          ).on(
              'afterChange',
              function (event, slick, currentSlide, nextSlide) {
                  animateBrandSlideStart()
              }
          )
          .on(
              'beforeChange',
              function (event, slick, currentSlide, nextSlide) {
                  $('.js-brand_slider .slick-active img').css({
                      opacity: 0
                  })
              }
          )
          .slick({
              slidesToShow: 5,
              slidesToScroll: 5,
              dots: false,
              centerMode: false,
              focusOnSelect: true,
              autoplay: true,
              speed: 300,
              autoplaySpeed: 4500,
              initialSlide: 0,
              infinite: true,
              pauseOnHover: false,
              swipe: false,
              touchMove: false,
              draggable: false,
              arrows: false,
              edgeFriction: 0,
              pauseOnFocus: false,
              responsive: [{
                  breakpoint: 640,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                  }
              },{
                  breakpoint: 420,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                  }
              }]
          });
  }
}


// Price Filter
function initRangerSlider() {
  const priceRange = $(".js-filter-slider");
  if ($(priceRange).length) {
      priceRange.each(function (index, val) {
          const self = $(this),
              priceResult = self.parent().find(".js-filter-result"),
              currency = priceResult.data('currency');
          if (typeof ($.fn.slider) != 'undefined') {
              self.slider({
                  range: true,
                  min: 0,
                  max: 500,
                  values: [250, 750],
                  slide: function (event, ui) {
                      priceResult.val(currency + ui.values[0] + " - " + currency + ui.values[1]);
                  }
              });
          }
          priceResult.val(currency + self.slider("values", 0) + " - " + currency + self.slider("values", 1));
      });
  }
}


$('.nav-toggle').click(function(){
  $('.nav').addClass('is-open');
});
$('.js-nav-close').click(function(){
  $('.nav').removeClass('is-open');
});

initRangerSlider();
animateAbout();
initBrandSlider()
